import React, {ReactNode, useContext, useState} from 'react';
import {AccessTokenDto, login, loginExtend, ping, verifyMfaCode} from '../api/SecurityApi'
import {setClientToken} from "../api/Base";
import { logBackend } from '../api/LoggingApi';
import { Authority } from '../api/RoleManagementApi';
//import jwtDecode from "jwt-decode";

interface userData {
    accessToken?: string,
    refreshToken?: string,
    softToken?: string,
    loggedInOnBackend: () => Promise<boolean>,
    loggedInClient?: boolean,
    setLoggedInClient?: (boolean) => void,
    logIn: (username: string, password: string) => Promise<AccessTokenDto>,
    verifyCode: (code: string) => Promise<boolean>,
    logInExtended: () => Promise<boolean>,
    logOut: () => void
    isAccessTokenExpired: () => boolean
    authorities?: Authority[]
    activeCompany?: number,
}
/*
interface JWTToken {
    name: string;
    exp: number;
    // whatever else is in the JWT.
}
*/

export const UserContext = React.createContext({});

interface userProps {
    children?: ReactNode
}

export default function UserContextProvider(props: userProps) {
    const [loggedInClient, setLoggedInClient] = useState(false);
    const [accessToken, setAccessToken] = useState(()=>{
        setClientToken(localStorage.getItem('accessToken'));
        return localStorage.getItem('accessToken');
    });
    const [softToken, setSoftToken] = useState(localStorage.getItem('softToken'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
    const [authorities, setAuthorities] = useState(localStorage.getItem('authorities'));
    const [activeCompany, setActiveCompany] = useState(localStorage.getItem('activeCompany'))
 
    async function logIn(username: string, password: string){
        return login({username,password}).then((res: AccessTokenDto) => {
            if(res.mfa){
                setSoftToken(res.accessToken)
                localStorage.setItem('softToken', res.accessToken);
            } else {
                setupTokenProperties(res)
            }
            return res;
        }).catch((error)=>{
            throw error
        })
    }

    function setupTokenProperties(res: AccessTokenDto) {
        setAccessToken(res.accessToken)
        setRefreshToken(res.refreshToken)
        localStorage.setItem('accessToken', res.accessToken);
        localStorage.setItem('refreshToken', res.refreshToken);
        setClientToken(res.accessToken);
        const decodedJwt = parseJwt(res.accessToken);
        setAuthorities(decodedJwt.authorities)
        localStorage.setItem('authorities',decodedJwt.authorities)
        setActiveCompany(decodedJwt.active_company)
        localStorage.setItem('activeCompany',decodedJwt.active_company)
    }

    function verifyCode(code: string) {
        return verifyMfaCode({softToken, code})
            .then((res: AccessTokenDto) => {
                setupTokenProperties(res);
                return res;
            }).catch((error) => {
                throw error
            })
    }

    async function logInExtended(){
        setClientToken(null)
        return loginExtend({refreshToken}).then((res: AccessTokenDto) => {
            setupTokenProperties(res)
            return true;
        }).catch((error)=>{
            return false;
        })
    }

    async function loggedInOnBackend(){
        if(accessToken!=null){
            return ping().then((response)=>{
                if(response=="pong"){
                    return true;
                } else return false;
            }).catch((error)=>{
                return false;
            })
        }else return false;
    }

    const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
          logBackend([{entry:"FRONTEND: Could not parse access token!"}])
          return null;
        }
      };

    function isAccessTokenExpired(){
        const decodedJwt = parseJwt(accessToken);
        if (decodedJwt==null || (decodedJwt.exp * 1000 < Date.now())) {
            return true;
        }else{
            return false;
        }
        /*
        if(accessToken==null) return true;
        let decodedToken = jwtDecode<JWTToken>(accessToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            return true
        } else {
            return false;
        }
        */
    }

    function logOut(){
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setClientToken(null)
        setAccessToken(null);
        setRefreshToken(null);
    }

    return(
        <UserContext.Provider value={{logIn, logOut, isAccessTokenExpired, accessToken,refreshToken, loggedInOnBackend, logInExtended, loggedInClient, setLoggedInClient, verifyCode, authorities, activeCompany}}>
            {props.children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => useContext(UserContext) as userData;
