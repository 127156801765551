import React from "react"
import UserContextProvider from "./src/context/UserContext"
import theme from "./src/config/Theme";
import {ThemeProvider} from "@material-ui/core/styles";
import './src/config/LocaleConfig';

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={theme}>
        <UserContextProvider>
            {element}
        </UserContextProvider>
    </ThemeProvider>
)