import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
    },
    palette: {
        primary: {
            main: 'rgb(45, 54, 67)',
            light: 'rgba(255, 255, 255, 0.1)',
        },
        secondary: {
            main: 'rgb(0, 163, 163)',
            dark: 'rgb(3, 143, 143)',
        },
    },
});
export default theme