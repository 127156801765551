import APIKit from './Base'

export interface LogDto{
    entry?:string
}

export const logBackend = (data: [LogDto]) => {
    return APIKit.post("/logging/",data)
        .then((response) => {
            return response.status
        })
        .catch((err) => {
            console.log("Network error");
        })
}