// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-companies-company-id-edit-tsx": () => import("./../../../src/pages/companies/[companyId]/edit.tsx" /* webpackChunkName: "component---src-pages-companies-company-id-edit-tsx" */),
  "component---src-pages-companies-company-id-index-tsx": () => import("./../../../src/pages/companies/[companyId]/index.tsx" /* webpackChunkName: "component---src-pages-companies-company-id-index-tsx" */),
  "component---src-pages-companies-create-tsx": () => import("./../../../src/pages/companies/create.tsx" /* webpackChunkName: "component---src-pages-companies-create-tsx" */),
  "component---src-pages-companies-index-tsx": () => import("./../../../src/pages/companies/index.tsx" /* webpackChunkName: "component---src-pages-companies-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-mfa-setup-tsx": () => import("./../../../src/pages/mfa/setup.tsx" /* webpackChunkName: "component---src-pages-mfa-setup-tsx" */),
  "component---src-pages-mfa-verify-tsx": () => import("./../../../src/pages/mfa/verify.tsx" /* webpackChunkName: "component---src-pages-mfa-verify-tsx" */),
  "component---src-pages-products-create-tsx": () => import("./../../../src/pages/products/create.tsx" /* webpackChunkName: "component---src-pages-products-create-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-product-id-edit-tsx": () => import("./../../../src/pages/products/[productId]/edit.tsx" /* webpackChunkName: "component---src-pages-products-product-id-edit-tsx" */),
  "component---src-pages-products-product-id-index-tsx": () => import("./../../../src/pages/products/[productId]/index.tsx" /* webpackChunkName: "component---src-pages-products-product-id-index-tsx" */),
  "component---src-pages-products-product-id-link-history-tsx": () => import("./../../../src/pages/products/[productId]/link-history.tsx" /* webpackChunkName: "component---src-pages-products-product-id-link-history-tsx" */),
  "component---src-pages-products-product-id-linking-tsx": () => import("./../../../src/pages/products/[productId]/linking.tsx" /* webpackChunkName: "component---src-pages-products-product-id-linking-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-standards-create-tsx": () => import("./../../../src/pages/standards/create.tsx" /* webpackChunkName: "component---src-pages-standards-create-tsx" */),
  "component---src-pages-standards-index-tsx": () => import("./../../../src/pages/standards/index.tsx" /* webpackChunkName: "component---src-pages-standards-index-tsx" */),
  "component---src-pages-standards-standard-id-edit-tsx": () => import("./../../../src/pages/standards/[standardId]/edit.tsx" /* webpackChunkName: "component---src-pages-standards-standard-id-edit-tsx" */),
  "component---src-pages-standards-standard-id-index-tsx": () => import("./../../../src/pages/standards/[standardId]/index.tsx" /* webpackChunkName: "component---src-pages-standards-standard-id-index-tsx" */),
  "component---src-pages-tasks-tsx": () => import("./../../../src/pages/tasks.tsx" /* webpackChunkName: "component---src-pages-tasks-tsx" */),
  "component---src-pages-users-create-tsx": () => import("./../../../src/pages/users/create.tsx" /* webpackChunkName: "component---src-pages-users-create-tsx" */),
  "component---src-pages-users-index-tsx": () => import("./../../../src/pages/users/index.tsx" /* webpackChunkName: "component---src-pages-users-index-tsx" */),
  "component---src-pages-users-roles-tsx": () => import("./../../../src/pages/users/roles.tsx" /* webpackChunkName: "component---src-pages-users-roles-tsx" */),
  "component---src-pages-users-user-id-edit-tsx": () => import("./../../../src/pages/users/[userId]/edit.tsx" /* webpackChunkName: "component---src-pages-users-user-id-edit-tsx" */),
  "component---src-pages-users-user-id-index-tsx": () => import("./../../../src/pages/users/[userId]/index.tsx" /* webpackChunkName: "component---src-pages-users-user-id-index-tsx" */)
}

