import APIKit from "./Base";
import { logBackend } from './LoggingApi'

export interface LoginDto{
    username?:string,
    password?:string
}
export interface VerifyCodeDto {
    softToken?:string,
    code?:string
}
export interface MfaSetupQrCodeDto{
    qrCodeImage?:string
}
export interface AccessTokenDto{
    accessToken:string,
    refreshToken:string,
    mfa: boolean
}
export interface RefreshTokenDto{
    refreshToken:string
}

export const login = (data: LoginDto) =>{
    return APIKit.post("/security/login",data)
        .then<AccessTokenDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}

export const verifyMfaCode = (data: VerifyCodeDto) =>{
    return APIKit.post("/mfa/verify",data)
        .then<AccessTokenDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}
export const setupMfaCode = () => {
    return APIKit.post("/mfa/setup")
        .then<MfaSetupQrCodeDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}
export const disableMfaCode = () => {
    return APIKit.post("/mfa/disable")
        .catch((err) => {
            throw(err)
        })
}
export const isMfaActive = () => {
    return APIKit.get("/mfa/active")
        .then<boolean>((response) => {
            return response.data
        }).catch((err) => {
            throw(err)
        })
}

export const loginExtend = (data: RefreshTokenDto) =>{
    return APIKit.post("/security/login/extend",data)
        .then<AccessTokenDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}

export const ping = () => {
    return APIKit.get("/ping")
        .then(response => {
            return response.data})
        .catch(err => {
            throw(err)
        })
}
